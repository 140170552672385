<template>
    <div class="education-wrapper">
        <div class="education-header">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item v-if="role === 1" :to="{ path: '/schooladmin/index' }">教务管理</el-breadcrumb-item>
                <el-breadcrumb-item v-else-if="role === 2" :to="{ path: '/trainadmin/index' }">教务管理</el-breadcrumb-item>
                <el-breadcrumb-item>班级管理</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button class="btn-blue" @click="addBtn">创建班级</el-button>
        </div>
        <el-table :data="classList" border style="width: 100%; margin-top: 20px; flex: 1" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F2F3FA'}"
                  :cell-style="{fontSize: '14px',color: '#343441'}">
            <el-table-column prop="name" align="center" label="班级名称"></el-table-column>
            <el-table-column prop="student_count" align="center" label="班级人数"></el-table-column>
<!--            <el-table-column prop="college_name" align="center" label="归属学校"></el-table-column>-->
            <el-table-column prop="create_time" align="center" label="创建时间"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link :underline="false" class="link-blue" @click="editBtn(scope.row)">编辑</el-link>
                    <el-link :underline="false" type="danger" @click="delBtn(scope.row.id)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pager-center" style="margin-top: 10px;"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       :total="listPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="pagesCurrentChange">
        </el-pagination>

<!--        添加编辑弹窗-->
        <el-dialog :title="dialogTitle" :visible.sync="addDialog" width="500px" custom-class="dialog-blue"
                   @close="resetForm()" @opened="openDialog" :close-on-click-modal="false">
            <el-form :model="addForm" ref="addForm" :rules="addRules" label-position="right" label-width="100px">
                <el-form-item label="班级名称" prop="name">
                    <el-input v-model="addForm.name" ref="cursorInput" autocomplete="off" placeholder="2-20字"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align: center">
                <el-button size="medium" class="btn-blue" @click="addToForm('addForm')">确 定</el-button>
                <el-button size="medium" @click="addDialog = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                classList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                // S 添加弹窗
                dialogTitle: '',
                addDialog: false,
                distinguishBtn: '',
                addForm: {
                    id: '',
                    name: '',
                },
                addRules: {
                    name: [
                        { required: true, message: '请输入班级名称', trigger: 'blur' },
                        { min: 2, max: 20, message: '长度在 2 到 20 个字', trigger: 'blur' }
                    ],
                },
                // E 添加弹窗
                role: Number(localStorage.getItem('role')),
            }
        },
        mounted() {
            this.getClassList();
        },
        methods: {
            // 获取班级列表
            getClassList() {
                let params = {
                    paging: 1,
                    page: this.listPages.currentPageNum,
                    pageSize: this.listPages.eachPageNum,
                }
                this.$http.axiosGetBy(this.$api.collegeClass, params, (res) => {
                    if (res.code === 200) {
                        this.classList = res.data.data
                        this.listPages.total = res.data.total
                        if (this.listPages.total !== 0 && this.classList.length === 0) {
                            this.listPages.currentPageNum--;
                            this.getClassList();
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 切换分页
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getClassList();
            },
            // 打开弹窗光标
            openDialog() {
                this.$refs.cursorInput.focus();
            },
            // 添加
            addBtn() {
                this.dialogTitle = '创建班级'
                this.distinguishBtn = 'add'
                this.addDialog = true
            },
            // 编辑
            editBtn(row) {
                this.dialogTitle = '编辑班级'
                this.distinguishBtn = 'edit'
                this.addDialog = true
                this.addForm.id = row.id
                this.addForm.name = row.name
            },
            // 添加表单确定
            addToForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.distinguishBtn === 'add') {
                            let formData = new FormData();
                            formData.append('name', this.addForm.name)
                            this.$http.axiosPost(this.$api.collegeClassAdd, formData, (res) => {
                                if (res.code === 200) {
                                    this.$message.success(res.msg);
                                    this.getClassList();
                                    this.addDialog = false;
                                    this.resetForm();
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        } else if (this.distinguishBtn === 'edit') {
                            let formData = new FormData();
                            formData.append('id', this.addForm.id)
                            formData.append('name', this.addForm.name)
                            this.$http.axiosPost(this.$api.collegeClassUpdate, formData, (res) => {
                                if (res.code === 200) {
                                    this.$message.success(res.msg)
                                    this.getClassList();
                                    this.addDialog = false;
                                    this.resetForm();
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            // 删除
            delBtn(id) {
                this.$confirm('是否删除该班级，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue',
                    type: 'warning',
                }).then(() => {
                    this.$http.axiosPost(this.$api.collegeClassDel, {id: id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg)
                            this.getClassList();
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
            // 重置表单
            resetForm() {
                this.addForm = {
                    id: '',
                    name: '',
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .education-wrapper {
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .education-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .el-breadcrumb {
                line-height: 40px;
            }
        }
    }
    .el-link + .el-link {
        margin-left: 10px;
    }
</style>