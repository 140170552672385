import { render, staticRenderFns } from "./ClassManage.vue?vue&type=template&id=15a65114&scoped=true"
import script from "./ClassManage.vue?vue&type=script&lang=js"
export * from "./ClassManage.vue?vue&type=script&lang=js"
import style0 from "./ClassManage.vue?vue&type=style&index=0&id=15a65114&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15a65114",
  null
  
)

export default component.exports